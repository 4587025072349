import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Container, GridContainer, H2 } from "@util/standard";
import { Query } from "@graphql-types";
import { ListItemContainer } from "./styles";
import { Link } from "@sub";

const WhatWeDoCapabilities = () => {
  const { allSanityWhatWeDoInner }: Query = useStaticQuery(graphql`
    {
      allSanityWhatWeDoInner(filter: {_rawPageContent: {ne: null}}) {
        nodes {
          _id
          name
          slug {
            current
          }
        }
      }
    }
  `);

  if (!allSanityWhatWeDoInner?.nodes)  return null;

  return (
    <Container
      flexDirection="column"
      margin="0px auto"
      mobileWidth="100%"
      tabletMargin="0 0 25px 0"
    >
      <H2>Capabilities.</H2>
      <GridContainer width="100%" repeat={3} columnGap="20px" rowGap="15px">
        {allSanityWhatWeDoInner?.nodes?.map((capability) => {
          if (!capability) return null;
          
          return (
            <ListItemContainer key={capability._id}>
              <Link
                bold
                appendedUrl={`/${capability.slug?.current}/`}
                linktext={`${capability.name} >`}
              />
            </ListItemContainer>
          );
        })}
      </GridContainer>
    </Container>
  );
};

export default WhatWeDoCapabilities;
