import styled from "styled-components";

import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container, GridContainer, H2 } from "@util/standard";
import { Image } from "@sub";
import { moveDown, moveUp } from "@util/styles";

export const TextContainer = styled(Container)`
  width: 70%;
  margin: 80px auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
    margin: 30px auto;
  }
`;

export const ListItemContainer = styled(Container)`
  width: 100%;
  margin: auto;
  padding: 15px 0;
  border-bottom: 1px solid ${colors.flightBlue};
`;

export const StyledImage = styled(Image)<{
  isFirst?: boolean;
  isOddNumber?: boolean;
}>`
  height: 100%;
  width: 100%;
  max-height: 700px;
  max-width: 700px;
  margin: auto;
  ${({ isFirst }) => isFirst && `top: 20%;`};
  ${({ isFirst, isOddNumber }) => isOddNumber && !isFirst && `top: -30%;`};
  position: absolute;
  img {
    object-fit: contain !important;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: 0px;
    height: 220px;
    width: 220px;
    padding-top: 40px;
  }
`;

export const ImageContainer = styled(Container)<{ hover?: boolean }>`
  height: 100%;
  position: relative;
  margin: 0;
  ${moveDown};

  ${({ hover }) => hover && `${moveUp}`};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

export const SectionContainer = styled(Container)<{
  hasBlueBackground?: boolean;
}>`
  margin: 0px auto;
  width: 100%;
  background: ${(props) =>
    props.hasBlueBackground
      ? `linear-gradient(${colors.flightBlue} 90%, ${colors.white} 10%)`
      : `${colors.white}`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    background: ${(props) =>
      props.hasBlueBackground ? `${colors.flightBlue}` : `${colors.white}`};
  }
`;

export const SectionGrid = styled(GridContainer)<{ reverse?: boolean }>`
  width: 80%;
  margin: 0 auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ reverse }) =>
      reverse &&
      `div:nth-child(1) { order: 2; } div:nth-child(2) { order: 1; }`};
  }
`;
