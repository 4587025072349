import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import WhatWeDoInnerHero from "@components/whatWeDoInner/whatWeDoInnerHero";
import WhatWeDoInnerImageTextBlock from "@components/whatWeDoInner/whatWeDoInnerImageTextBlock";
import WhatWeDoOfferings from "@components/whatWeDoInner/whatWeDoOfferings";
import RelatedWork from "@components/whatWeDoInner/relatedWork";
import WhatWeDoCapabilities from "@components/whatWeDo/whatWeDoCapabilities";
import SEO from "@components/shared/seo";
import NextOrPreviousWork from "@components/work/nextOrPreviousWork";
import ContactForm from "@components/contact/contactForm";
import { Container } from "@util/standard";
import ImageColumns from "@components/workInner/imageColumns";
import {
  isSanityImageBlock,
  isSanityImageColumns,
  isSanityImageTextColumn,
  isSanityOtherServices,
  isSanityRelatedWork,
  isSanityScrollContainer,
  isSanityVideoBlock,
} from "@util/types";
import { FluidObject } from "gatsby-image";
import { Image, Video } from "@components/shared/sub";
import ScrollContainer from "@components/workInner/scrollContainer";
import { VideoWrapper } from "./template.styles";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}

interface Props extends PageProps {
  data: Query;
  pageContext: any;
}

export default function WhatWeDoInner({ data, pageContext }: Props) {
  const {
    allSanityWhatWeDoInner: {
      edges: [{ node: page }],
    },
  } = data;

  if (page == null) {
    return null;
  }

  return (
    <div>
      <SEO seoData={page.seo} slug={pageContext?.slug} />
      <WhatWeDoInnerHero
        pageName={page.contentPreview?.title}
        summary={page.contentPreview?.summary}
        showContactForm={page.contactShow}
        richText={page.contentPreview?.summaryRichText}
      />
      <Container flexDirection="column" width="90%" margin="0 auto">
        {page.pageContent &&
          page.pageContent.map((content) => {
            if (content == null) {
              return null;
            }

            if (isSanityImageColumns(content)) {
              return (
                <ImageColumns width="100%" data={content} key={content._key} />
              );
            }
            if (isSanityImageTextColumn(content)) {
              return (
                <Container
                  width="100%"
                  margin="0 0 145px 0"
                  tabletMargin="0 0 25px 0"
                >
                  <WhatWeDoInnerImageTextBlock
                    key={content._key}
                    content={content}
                  />
                </Container>
              );
            }

            if (isSanityImageBlock(content)) {
              return (
                <Container
                  width={`${content.width ?? 100}%`}
                  height={`${content.height ?? 100}%`}
                  tabletWidth="100%"
                  margin="0 auto 120px auto"
                  tabletMargin="0 auto 25px auto"
                >
                  <Image fluid={content.image?.asset?.fluid as FluidObject} />
                </Container>
              );
            }

            if (isSanityOtherServices(content)) {
              return <WhatWeDoOfferings key={content._key} content={content} />;
            }

            if (isSanityRelatedWork(content)) {
              return <RelatedWork key={content._key} content={content} />;
            }

            if (isSanityScrollContainer(content)) {
              return <ScrollContainer data={content} />;
            }
            if (isSanityVideoBlock(content)) {
              return (
                <VideoWrapper
                  key={content._key}
                  tabletMarginOverride="0 auto 25px auto"
                >
                  <Video
                    background
                    url={content.vimeoURL as string}
                    volume={false}
                    foregroundOpen
                  />
                </VideoWrapper>
              );
            }
            return null;
          })}
      </Container>
      <WhatWeDoCapabilities />

      {page.contactShow && (
        <Container id="contact-form" flexDirection="column" margin="40px auto">
          <ContactForm showTitle />
        </Container>
      )}
      <Container
        width="100%"
        margin="120px auto 0 auto"
        tabletMargin="25px auto 0 auto"
      >
        <NextOrPreviousWork />
      </Container>
    </div>
  );
}

export const query = graphql`
  query whatWeDoInnerPageQery($slug: String) {
    allSanityWhatWeDoInner(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          seo {
            pageDescription
            pageKeyWords
            pageTitle
            slug {
              current
            }
            ogImage {
              asset {
                url
              }
            }
          }
          slug {
            current
          }
          contentPreview {
            title
            summary
            slug {
              current
            }
            summaryRichText {
              _rawRichTextContent
            }
          }
          pageContent {
            ... on SanityRelatedWork {
              ...sanityRelatedWork
            }
            ... on SanityImageColumns {
              ...sanityImageColumns
            }
            ... on SanityImageBlock {
              ...sanityImageBlock
            }
            ... on SanityScrollContainer {
              ...sanityScrollContainer
            }
            ... on SanityVideoBlock {
              ...sanityVideoBlock
            }
            ... on SanityOtherServices {
              ...sanityOtherServices
            }
            ... on SanityImageTextColumn {
              ...sanityImageTextColumn
            }
          }
          contactShow
        }
      }
    }
  }
`;
