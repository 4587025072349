import React, { useState } from "react";
import styled from "styled-components";
import HubspotForm from "react-hubspot-form";

import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container, H2, P } from "@util/standard";
import FeaturedWork from "@components/home/featuredWork";

const MainContainer = styled(Container)`
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 auto;
  }
`;

const FormContainer = styled(Container)`
  flex-direction: column;
  width: 90%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
  }
`;

interface Props {
  showTitle?: boolean;
}

const ContactForm = (props: Props) => {
  const [submitted, setSubmitted] = useState(false);
  const { showTitle } = props;

  const ThankYou = () => {
    return (
      <Container autoWidthNoMargin flexDirection="column">
        <FormContainer>
          <H2>Thank you for getting in touch!</H2>
          <P>
            We’ll be in touch as soon as possible. In the meantime, feel free to
            scroll down and check out some previous work.
          </P>
        </FormContainer>
        <FeaturedWork />
      </Container>
    );
  };

  return (
    <MainContainer>
      {submitted ? (
        <ThankYou />
      ) : (
        <FormContainer>
          {showTitle && <H2>Get in touch</H2>}

          <HubspotForm
            portalId="5521628"
            formId="31463d56-716d-457e-8b6b-bc6f92819cbe"
            onSubmit={() => setSubmitted(true)}
            loading={<div>Loading...</div>}
          />
        </FormContainer>
      )}
    </MainContainer>
  );
};

export default ContactForm;
