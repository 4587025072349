import React from "react";
import styled from "styled-components";

import { Container, GridContainer, H2 } from "@util/standard";
import WorkPreview from "@components/shared/workPreview";
import { LinkButton } from "@components/shared/sub";
import { pages } from "@util/constants";
import { SanityRelatedWork } from "@graphql-types";

interface Props {
  content: SanityRelatedWork;
}

const StyledLinkButton = styled(LinkButton)`
  position: absolute;
  top: 0px;
  right: 0px;
`;

function RelatedWork(props: Props) {
  const { content } = props;

  return (
    <Container display="block" mobileWidth="100%">
      <Container width="100%" position="relative">
        <H2>Related Work</H2>
        <StyledLinkButton pathTo={pages.whatWeDo}>{"<"} Back</StyledLinkButton>
      </Container>
      <GridContainer repeat={2} mobileRepeat={1}>
        {content?.relatedWorkList?.map((work) => {
          return <WorkPreview work={work} />;
        })}
      </GridContainer>
    </Container>
  );
}

export default RelatedWork;
