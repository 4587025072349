import React, { useState } from "react";
import styled from "styled-components";
import { FluidObject } from "gatsby-image";

import { SanityOtherServices, SanityPreview } from "@graphql-types";
import { Container, P, BlueBackdrop, H3, H2 } from "@util/standard";
import { Image, LinkButton } from "@sub";
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  colors,
  pages,
} from "@util/constants";
import { moveUp } from "@util/styles";

interface Props {
  content: SanityOtherServices;
}

const Wrapper = styled(Container)`
  position: relative;
  width: 100%;
  margin: 20px 0 0 0;
  padding: 100px 0 100px 0;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0px 0 50px 0;
  }
`;

const TextWrapper = styled.div`
  max-width: 800px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: auto;
    background-color: ${colors.flightBlue};
    padding: 60px 40px 70px 40px;
  }
`;

const ContentContainer = styled(Container)`
  flex-direction: row;
  padding: 50px 0 0 0;
  width: 100%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0;
    flex-direction: column;
    margin-top: 40px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 70%;
  }
`;

const TextContainer = styled(Container)`
  flex-direction: column;
  margin: 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

const ImageWrapper = styled.div<{ hover: boolean }>`
  ${({ hover }) => hover && moveUp}

  width: 80%;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const PreviewContainer = styled(Container)`
  width: 100%;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 20px;
  }
`;

function WhatWeDoOfferings(props: Props) {
  const Preview = ({ offering }: { offering: SanityPreview }) => {
    const [hover, setHover] = useState(false);
    const moreText = hover ? `Find out more \xa0\xa0 >` : "Find out more >";

    return (
      <PreviewContainer
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ImageWrapper hover={hover}>
          <Image
            fluid={offering.image?.asset?.fluid as FluidObject}
            objectFit="contain"
            minHeight="250px"
          />
        </ImageWrapper>

        <TextContainer>
          <H3 underlined={hover}>{offering.title}</H3>
          <P color="flightBlue">{offering.summary}</P>
          <LinkButton
            margin="15px 0 0 0"
            color="flightBlue"
            pathTo={`${pages.whatWeDo}/${offering.slug?.current}`}
          >
            {moreText}
          </LinkButton>
        </TextContainer>
      </PreviewContainer>
    );
  };
  const { title, subtext, servicesList } = props.content;

  return (
    <Wrapper>
      <BlueBackdrop />
      <Container
        width="70%"
        mobileDimensions={{ width: "100%" }}
        flexDirection="column"
        zIndex={1}
      >
        <TextWrapper>
          <H2 color="white">{title}</H2>
          <P color="white" noMargin>
            {subtext}
          </P>
        </TextWrapper>

        <ContentContainer>
          {servicesList?.map((service) => {
            const offering = service?.contentPreview;
            if (offering == null) {
              return null;
            }

            return <Preview offering={offering} />;
          })}
        </ContentContainer>
      </Container>
    </Wrapper>
  );
}

export default WhatWeDoOfferings;
