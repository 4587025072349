import React from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";

import { BlocksContent, Button, LinkButton } from "@sub";
import { Maybe } from "@graphql-types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT, pages } from "@util/constants";
import { Container, TextButton, P, H1 } from "@util/standard";

interface Props {
  pageName: Maybe<string> | undefined;
  summary: Maybe<string> | undefined;
  showContactForm?: Maybe<boolean> | undefined;
  richText?: Maybe<any> | undefined;
}

const Wrapper = styled(Container)`
  width: 90%;
  margin: 250px auto 100px 5%;
  // max-width: 66%;

  flex-direction: column;
  margin: 250px auto 140px auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 49px auto;
  }
`;

const CenteredBlockContainer = styled(Container)`
  margin-top: 20px;
  width: 60%;
  flex-direction: column;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 5px;
  }
`;

const Summary = styled(Container)`
  width: 100%;
  display: block;
  max-width: 66%;
  margin: 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    max-width: 100%;
  }
`;

const PageTitle = styled(H1)`
  font-size: 100px;
  font-style: normal;
  font-weight: 400;
  line-height: 100px;
  margin: 0px auto 60px auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    line-height: 1.1;
    font-size: 50px;
    margin: 100px auto 50px 0;
    width: 90%;
  }
`;

function WhatWeDoInnerHero(props: Props) {
  const { pageName, summary, showContactForm, richText } = props;

  return (
    <Wrapper>
      {/* <CenteredBlockContainer> */}
      <Summary>
        <PageTitle>{pageName}</PageTitle>

        {richText ? (
          <BlocksContent blocks={richText._rawRichTextContent} />
        ) : (
          <P margin="0px 0px 30px 0px">{summary}</P>
        )}

        {showContactForm ? (
          <Link to="#contact-form">Get in touch</Link>
        ) : (
          <Button linkTo="/contact" text="Get in touch about this service >" />
        )}
      </Summary>
      {/* </CenteredBlockContainer> */}
    </Wrapper>
  );
}

export default WhatWeDoInnerHero;
