import React from "react";
import styled from "styled-components";
import { FluidObject } from "gatsby-image";

import { BlocksContent, Image } from "@sub";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container, GridContainer } from "@util/standard";
import { SanityImageTextColumn } from "@graphql-types";

interface Props {
  content: SanityImageTextColumn;
}

const ImageContainer = styled(Container)`
  width: 100%;
  margin: 0;
  height: auto;
  overflow: hidden;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const SecondColumnContainer = styled(Container)`
  width: 90%;
  text-align: left;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    margin: 25px auto 0 auto;
    padding: 0px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;

    h2 {
      line-height: 1.1;
    }
  }

  p {
    font-size: 20px;
  }

  a {
    color: #0000ff;
  }

  h3 a {
    font-size: 30px;
  }
`;

function WhatWeDoInnerImageTextBlock(props: Props) {
  const { firstColumn, reverse, secondColumn } = props.content;

  return (
    <Container autoWidthNoMargin>
      <GridContainer
        repeat={firstColumn ? 2 : 1}
        tabletRepeat={1}
        direction={reverse ? "rtl" : "initial"}
        tabletDirection="initial"
      >
        <ImageContainer>
          {firstColumn && (
            <Image
              objectFit="contain"
              fluid={firstColumn.asset?.fluid as FluidObject}
            />
          )}
        </ImageContainer>
        <SecondColumnContainer>
          {secondColumn && (
            <BlocksContent blocks={secondColumn._rawColumnContent} />
          )}
        </SecondColumnContainer>
      </GridContainer>
    </Container>
  );
}

export default WhatWeDoInnerImageTextBlock;
