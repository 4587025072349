import React, { useState } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import styled from "styled-components";

import { Container, GridContainer, TextButton } from "@util/standard";
import { Query } from "@graphql-types";
import WorkPreview from "@components/shared/workPreview";
import { MOBILE_BREAKPOINT, pages } from "@util/constants";

const Wrapper = styled(Container)`
  margin-top: 100px;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin: 0 auto 50px auto;
  }
`;

interface Props {
  workSlug?: string | undefined | null;
}

const NextOrPreviousWork = ({ workSlug }: Props) => {
  const {
    allSanityWork: { nodes },
  }: Query = useStaticQuery(graphql`
    {
      allSanityWork(sort: { fields: order, order: ASC }) {
        nodes {
          _id
          title
          slug {
            current
          }
          summary
          images {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);

  if (nodes == null) {
    return null;
  }

  const workIndex = nodes.findIndex((work) => work.slug?.current === workSlug);
  const [index, setIndex] = useState(workIndex !== -1 ? workIndex : 1);
  const previous = nodes[index === 0 ? nodes.length - 1 : index - 1];
  const next = nodes[index + 1 === nodes.length ? 0 : index + 1];

  const itemsToLoad = [previous, next];

  const handlePreviousClick = () => {
    if (index === 0) {
      setIndex(nodes.length - 1);
    } else {
      setIndex(index - 1);
    }
  };

  const handleNextClick = () => {
    if (index + 1 === nodes.length) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  return (
    <Wrapper hideOnMobile>
      <Container width="100%" justifyContent="space-between">
        <TextButton
          onClick={handlePreviousClick}
        >{`< Previous project`}</TextButton>
        <TextButton onClick={() => navigate("/work")}>
          {workSlug ? "Back to work" : "Recent work"}
        </TextButton>
        <TextButton onClick={handleNextClick}>{`Next project >`}</TextButton>
      </Container>
      <GridContainer repeat={2} margin="0 0 100px 0">
        {itemsToLoad.map((work) => {
          if (work == null) {
            return null;
          }

          return <WorkPreview key={work._id} work={work} />;
        })}
      </GridContainer>
    </Wrapper>
  );
};

export default NextOrPreviousWork;
